/* POSITION UTILITY CLASSES */

/* Position */
.static {
  position: static;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.fixed {
  position: fixed;
}

.sticky {
  position: sticky;
}

// FLOAT
.f_right {
  float: right;
}

.position_middle {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.mt_8{
  margin-top: 2rem;
}