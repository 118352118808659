.p_0 {
  padding: 0;
}

.p_1 {
  padding: 0.25rem;
}

.p_2 {
  padding: 0.5rem;
}

.p_3 {
  padding: 0.75rem;
}

.p_4 {
  padding: 1rem;
}

.p_6 {
  padding: 3rem;
}

.p_8 {
  padding: 2rem !important;
}

.p_12 {
  padding: 3rem;
}

// PADDING X
.px_0 {
  padding-left: 0;
  padding-right: 0;
}

.px_4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px_8 {
  padding-inline: 2rem;
}

.px_16 {
  padding-left: 4rem;
  padding-right: 4rem;
}

// PADDING LEFT
.pl_0 {
  padding-left: 0;
}

.pl_1 {
  padding-left: 0.25rem;
}

.pl_2 {
  padding-left: 0.5rem;
}

.pl_3 {
  padding-left: 0.75rem;
}

.pl_4 {
  padding-left: 1rem;
}

// PADDING LEFT
.pr_0 {
  padding-right: 0;
}

.pr_1 {
  padding-right: 0.25rem;
}

.pr_2 {
  padding-right: 0.5rem;
}

.pr_3 {
  padding-right: 0.75rem;
}

.pr_4 {
  padding-right: 1rem;
}

.py_8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.py_5 {
  padding-block: 1.25rem;
}
