@import "./variables";

.br_0 {
  border-radius: 0;
}

.br_1 {
  border-radius: 0.25rem;
}

.br_2 {
  border-radius: 0.5rem;
}

.br_3 {
  border-radius: 0.75rem;
}

.br_4 {
  border-radius: 1rem;
}

.br_5 {
  border-radius: 1.25rem;
}

.br_6 {
  border-radius: 1.5rem;
}

.br_8 {
  border-radius: 2rem;
}

.br_50p {
  border-radius: 50%;
}

// BORDERS
.b_0 {
  border: 0 !important;
}

.b_1_grey {
  border: 1px solid $grey;
}

.bt_1_grey{
  border-top: 1px solid $grey;
}

.b_2_grey{
  border: 2px solid $grey;
}

.b_r_1_grey {
  border-right: 1px solid $grey;
}

.b_r_2_grey {
  border-right: 2px solid $grey;
}

.b_3_grey {
  border: 3px solid $grey;
}
