@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,600&family=Nunito:wght@300;400;500;600;700&display=swap');

// Responsive Breakpoints (ant design)
$xs-br: 575.98px;
$sm-br: 576px;
$md-br: 768px;
$lg-br: 992px;
$xl-br: 1200px;
$xxl-br: 1600px;
$container-padding-x: 1rem;
$base-font-size: 1rem;

// Font-families
$font-size-base: 1rem;
$font-primary: 'Nunito Sans', sans-serif;
$font-secondary: 'Nunito', sans-serif;

// Colors
$primary: rgba(0, 94, 184, 1);
$primary50: rgba(0, 94, 184, 1);
$primary100: rgb(0, 21, 105);
$text-secondary: rgb(4, 1, 1);
$white: rgb(255, 255, 255, 1);
$success: rgba(14, 98, 69, 1);
$danger: rgb(255, 9, 9);
$grey: rgb(217, 217, 217, 1);
$grey100: rgba(80, 80, 86, 1);
$grey200: rgba(61, 58, 65, 1);
$black: rgba(0, 0, 0, 1);
$black100: rgba(17, 17, 51, 0.2);
$black200: rgba(33, 31, 37, 1);
$white: rgba(255, 255, 255, 1);
$snowWhite: rgba(240, 255, 245, 1);
$pink: rgba(255, 0, 122, 1);
$purple: rgba(119, 99, 247, 1);
$snowBlue: #E7F0FC;
$paleBlue: #92DEED;

/**
* GRADIENTS
*/
$primary-gradient: linear-gradient(90deg, #005EB8 0%, #32A9E8 50.52%, #00DBDE 100%);