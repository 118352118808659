/* FLEX UTILITY CLASSES */

/* Display */
.flex {
  display: flex;
}

/* Flex Direction */
.row {
  flex-direction: row;
}

.row_reverse {
  flex-direction: row-reverse;
}

.col {
  flex-direction: column;
}

.col_reverse {
  flex-direction: column-reverse;
}

/* Flex Wrap */
.wrap {
  flex-wrap: wrap;
}

.nowrap {
  flex-wrap: nowrap;
}

.wrap_reverse {
  flex-wrap: wrap-reverse;
}

/* Justify Content */
.justify_start {
  justify-content: flex-start;
}

.justify_end {
  justify-content: flex-end;
}

.justify_center {
  justify-content: center;
}

.justify_between {
  justify-content: space-between;
}

.justify_around {
  justify-content: space-around;
}

.justify_evenly {
  justify-content: space-evenly;
}

/* Align Items */
.align_start {
  align-items: flex-start;
}

.align_end {
  align-items: flex-end;
}

.align_center {
  align-items: center;
}

.align_baseline {
  align-items: baseline;
}

.align_stretch {
  align-items: stretch;
}

/* Flex Grow */
.grow_0 {
  flex-grow: 0;
}

.grow_1 {
  flex-grow: 1;
}

/* Align Self */
.align_self_start {
  align-self: flex-start;
}

.align_self_end {
  align-self: flex-end;
}

.align_self_center {
  align-self: center;
}

.align_self_baseline {
  align-self: baseline;
}

.align_self_stretch {
  align-self: stretch;
}

/* Flex Shrink */
.shrink_0 {
  flex-shrink: 0;
}

.shrink_1 {
  flex-shrink: 1;
}

/* Flex Basis */
.basis_auto {
  flex-basis: auto;
}

.basis_0 {
  flex-basis: 0;
}

.basis_full {
  flex-basis: 100%;
}

/* FLEX ITEM UTILITY CLASSES */

/* Flex Shrink */
.shrink_0 {
  flex-shrink: 0;
}

.shrink_1 {
  flex-shrink: 1;
}

/* Flex Basis */
.basis_auto {
  flex-basis: auto;
}

.basis_0 {
  flex-basis: 0;
}

.basis_full {
  flex-basis: 100%;
}

/* GAP UTILITY CLASSES */
/* Gap */
.g_0 {
  gap: 0;
}

.g_1 {
  gap: 0.25rem;
}

.g_2 {
  gap: 0.5rem;
}

.g_3 {
  gap: 0.75rem;
}

.g_4 {
  gap: 1rem;
}

.g_5 {
  gap: 1.25rem;
}

.g_6 {
  gap: 1.5rem;
}

.g_7 {
  gap: 1.75rem;
}

.g_8 {
  gap: 2rem;
}


/* Column Gap */
.cg_0 {
  column-gap: 0;
}

.cg_1 {
  column-gap: 0.25rem;
}

.cg_2 {
  column-gap: 0.5rem;
}

.cg_3 {
  column-gap: 0.75rem;
}

.cg_4 {
  column-gap: 1rem;
}

.cg_5 {
  column-gap: 1.25rem;
}

.cg_6 {
  column-gap: 1.5rem;
}

.cg_7 {
  column-gap: 1.75rem;
}

.cg_8 {
  column-gap: 2rem;
}

/* Row Gap */
.rg_0 {
  row-gap: 0;
}

.rg_1 {
  row-gap: 0.25rem;
}

.rg_2 {
  row-gap: 0.5rem;
}

.rg_3 {
  row-gap: 0.75rem;
}

.rg_4 {
  row-gap: 1rem;
}

.rg_5 {
  row-gap: 1.25rem;
}

.rg_6 {
  row-gap: 1.5rem;
}

.rg_7 {
  row-gap: 1.75rem;
}

.rg_8 {
  row-gap: 2rem;
}

.center {
  @extend .flex,
  .justify_center,
  .align_center,
}