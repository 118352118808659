// CURSOR
.pointer {
  cursor: pointer;
}

.not_allowed {
  cursor: not-allowed;
}

.input {
  border-radius: 0.25rem;
}

.custom_input::placeholder {
  color: $white;
  opacity: 1;
}

.center .slick-center img {
  opacity: 1;
  transform: scale(1.15);
}

.center img {
  opacity: 0.8;
  scale: 0.8;
  transition: all 300ms ease;
}