.w_25 {
  width: 25%;
}

.w_30 {
  width: 30%;
}

.w_50 {
  width: 50%;
}

.w_75 {
  width: 75%;
}

.w_90 {
  width: 90%;
}

.w_100 {
  width: 100%;
}

.w_200 {
  width: 200px;
}

.w_300 {
  width: 300px;
}

.w_max_content {
  width: max-content !important;
}

.min_w_max_content {
  min-width: max-content;
}

.o_hidden {
  overflow: hidden;
}

.o_scroll {
  overflow: hidden;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 0.5rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 0.25rem;
  }

  scrollbar-width: thin;
  scrollbar-color: transparent transparent;

  &::-moz-scrollbar {
    width: 0.5rem;
  }

  &::-moz-scrollbar-thumb {
    background-color: transparent;
    border-radius: 0.25rem;
  }
}