// COLORS
.text_secondary {
  // to be used for all labels, table headers
  color: $text-secondary;
}

.white {
  color: $white !important;
}

.primary {
  color: $primary;
}

.primary_100 {
  color: $primary100
}

.secondary {
  color: black;
}

.info {
  color: yellow;
}

.success {
  color: $success;
}

.danger {
  color: $danger;
}

.grey_100 {
  color: $grey100;
}

.grey_200 {
  color: $grey200;
}

.black {
  color: $black;
}

.black_100 {
  color: $black100;
}

.black_200 {
  color: $black200;
}

.pink {
  color: $pink;
}

.purple {
  color: $purple;
}

.grey_200 {
  color: rgba($grey200, 0.7);
}

.green {
  color: green
}

// BACKGROUND COLORS
.bg_primary {
  background-color: $primary;
}

.bg_primary_100 {
  background-color: $primary100;
}

.bg_secondary {
  background-color: black;
}

// .bg_info {
//   background-color: $yellow;
// }

.bg_success {
  background-color: green;
}

.bg_danger {
  background-color: red;
}

.bg_white {
  background-color: $white;
}

.bg_black {
  background-color: $black;
}

.bg_shade {
  background-color: #F7F7FB;
}

.bg_snow_white {
  background-color: $snowWhite;
}

.bg_snow_blue {
  background-color: $snowBlue;
}

.bg_pale_blue{
  background-color: $paleBlue;
}



// linear gradients
.lg_primary {
  background: linear-gradient(to bottom, transparent 5rem, #005EB8 2.5rem);
}

.lg_primary_100 {
  background: linear-gradient(90deg, #7763F7 0%, #32A9E8 50.52%, #00DBDE 100%);
}