@import './utility_classes/exports.scss';

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  // font: inherit;
  font-size: $base-font-size;
  vertical-align: baseline;
  font-family: $font-primary;
}

html body {
  min-height: 100vh;
  width: 100%;

  img {
    display: block;
  }

  ul,
  ol {
    list-style: none;
  }
}

.h_product_btn {
  @extend .br_50p, .p_3, .tr_all;
  background-color: rgba($grey, 0.3);
  color: $black;

  &:hover {
    background-color: $purple;
    color: $white;
  }
}

.job_card {
  @extend .tr_all;
  align-self: stretch;

  .description {
    color: rgba($grey200, 0.6);
  }

  .apply_btn {
    padding: 0.75rem 1.5rem;
    color: $black200;
    border-radius: 25px;
    height: auto;
    @extend .bg_white, .b_1_grey, .fw_500;
  }

  &:hover {

    .description,
    .time {
      color: $white;
    }

    .apply_btn {
      &:hover {
        background-color: $white;
        @extend .sh, .black;
      }
    }

    color: $white !important;
    background-color: $purple !important;
  }
}

.values_btn {
  background-color: rgba($purple, 0.3);
  height: auto;
  padding: 1rem 2rem;
  border-radius: 30px;
  color: $purple;
  @extend .tr_all, .fs_sm;

  &:hover {
    background-color: $purple !important;
  }
}

.default_markup {
  * {
    box-sizing: border-box;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: bold;
    font-size: initial;
  }

  p {
    margin-bottom: 1rem;
  }

  a {
    color: #007bff;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }

  img {
    max-width: 100%;
    height: auto;
  }
}