@import './mixins';

.animate_spin_up {
  animation: spin_up 4s infinite
}

@keyframes spin_up {
  10% {
    transform: translateY(-105%);
  }

  25% {
    transform: translateY(-100%);
  }

  35% {
    transform: translateY(-205%);
  }

  50% {
    transform: translateY(-200%);
  }

  60% {
    transform: translateY(-305%);
  }

  75% {
    transform: translateY(-300%);
  }

  85% {
    transform: translateY(-405%);
  }

  100% {
    transform: translateY(-400%);
  }
}


/* Animation Utility Classes */
.an_sc_up {
  animation: scale-up 0.3s ease-in-out forwards;
}

.an_sc_do {
  animation: scale-down 0.3s ease-in-out forwards;
}

.tr_all {
  @include transition(all, 0.3s);
}

@keyframes scale-up {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}

@keyframes scale-down {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(0);
  }
}

.an_ro {
  animation: rotate 1s infinite linear;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.an_bounce {
  animation: bounce 0.5s infinite;
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(0);
  }
}

// Motion banner Animations
.motion-banner {
  @extend .o_hidden;
  opacity: 0;
  transition: opacity 1s;
  /* Increase the transition duration to 1 second */
}

.motion-banner.visible {
  opacity: 1;
}

.motion-content {
  transform: translateX(-100%);
  opacity: 0;
  transition: transform 1s, opacity 1s;
}

.motion-banner.visible .motion-content {
  transform: translateX(0);
  opacity: 1;
}

.motion-image {
  transform: translateX(100%);
  opacity: 0;
  transition: transform 1s, opacity 1s;
}

.motion-banner.visible .motion-image {
  transform: translateX(0);
  opacity: 1;
}