@import './variables';

// fonts
.font_primary {
  font-family: $font-primary;
}

.font_secondary {
  font-family: $font-secondary;
}

// Font sizes
.fs_xxs {
  font-size: 0.5rem;
}

.fs_xs {
  font-size: 0.75rem;
}

.fs_sm {
  font-size: 1rem;
}

.fs_md {
  font-size: 1.25rem;
}

.fs_lg {
  font-size: 1.5rem;
}

.fs_xl {
  font-size: 2rem;
}

.fs_xxl {
  font-size: 2.5rem;
}

.fs_xxxl {
  font-size: 3rem;
}

// LINE HEIGHT

.lh_xxs {
  line-height: 0.5rem;
}

.lh_xs {
  line-height: 0.75rem;
}

.lh_s {
  line-height: 1rem;
}

.lh_md {
  line-height: 1.25rem;
}

.lh_lg {
  line-height: 1.5rem;
}

.lh_xl {
  line-height: 2rem;
}

.lh_xxl {
  line-height: 2.5rem;
}

.lh_xxxl {
  line-height: 3rem;
}

// FONT WEIGHTs

.fw_100 {
  font-weight: 100;
}

.fw_200 {
  font-weight: 200;
}

.fw_300 {
  font-weight: 300;
}

.fw_400 {
  font-weight: 400;
}

.fw_500 {
  font-weight: 500;
}

.fw_600 {
  font-weight: 600;
}

.fw_700 {
  font-weight: 700;
}

.fw_800 {
  font-weight: 800;
}

.fw_900 {
  font-weight: 900;
}

/* TEXT DECORATION */
.td_none {
  text-decoration: none;
}

.td_underline {
  text-decoration: underline;
}

.td_overline {
  text-decoration: overline;
}

.td_line_through {
  text-decoration: line-through;
}

/* TEXT ALIGNMENT */
.ta_left {
  text-align: left;
}

.ta_center {
  text-align: center;
}

.ta_right {
  text-align: right;
}

/* TEXT TRANSFORM */
.tt_none {
  text-transform: none;
}

.tt_lowercase {
  text-transform: lowercase;
}

.tt_uppercase {
  text-transform: uppercase;
}

.tt_capitalize {
  text-transform: capitalize;
}

.fs_italic {
  font-style: italic;
}

// WHITE SPACE
.ws_nowrap {
  white-space: nowrap;
}

.td_no {
  text-decoration: none;
}

.ellipses {
  text-overflow: ellipsis;
}

.ta_justify {
  text-align: justify;
}

.l_spacing:hover {
  transition: all 0.3s ease;
  letter-spacing: 3px;
}