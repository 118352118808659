.flip-card {
  background-color: transparent;
  width: 250px;
  height: 350px;
  perspective: 1000px; //for 3D view
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
  width: 250px;
  height: 350px;
  background-color: #bbb;
  color: black;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.flip-card-back {
  width: 250px;
  height: 350px;
  background-image: linear-gradient(360deg, #0084ff 0%, #ffffff 100%);
  ;
  color: white;
  transform: rotateY(180deg);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
}

.linear_black {
  background-image: linear-gradient(to top, black, transparent);
  left: 0;
  right: 0;
}