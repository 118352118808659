.box_shadow_100 {
  box-shadow: 0 0.125rem 0.25rem 0 rgba(0, 0, 0, 0.02), 0 0 0 1px rgba(27, 31, 35, 0.15);
}

.sh_no {
  box-shadow: none !important;
}

.sh_sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.sh_md {
  box-shadow: 15px 23px 0.25rem rgba(229, 229, 229, 0.25)
}

.sh {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.sh_lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.sh_in {
  box-shadow: inset 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.h_sh:hover {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.h_sh_md:hover {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;
}

.png_sh:hover{
  filter: drop-shadow(0 0 20px rgba(0, 0, 0, 0.1));
}