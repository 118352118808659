// Import Ant Design variables
@import './variables';

// Update container breakpoints with Ant Design breakpoints
$container-max-widths: (
  xs: $xs-br,
  sm: $sm-br,
  md: $md-br,
  lg: $lg-br,
  xl: $xl-br,
  xxl: $xxl-br,
);

@mixin make-container($max-widths: $container-max-widths, $padding-x: $container-padding-x) {
  width: 90%;
  padding-right: $padding-x;
  padding-left: $padding-x;
  margin-right: auto;
  margin-left: auto;

  @each $breakpoint,
  $max-width in $max-widths {
    @media (min-width: $max-width) {
      max-width: $max-width;
    }
  }
}

.container {
  @include make-container();
}

.container_l {
  @include make-container();
  margin-right: initial;
}

.container_r {
  @include make-container();
  margin-left: initial;
}