@import './mixins';

.btn_reset {
  user-select: none;
  touch-action: manipulation;
  font-weight: 600;
  font-size: 1.25rem;
  text-transform: capitalize;
  font-family: $font-secondary;
  font-size: 1rem;
}

.btn_animate {
  transition: all 0.2s ease-in;

  &:is(:hover, :active) {
    transform: translateY(-2px);
    scale: 0.98;
  }
}

.btn_primary {
  color: white;
  font-size: 1.25rem;
  @include btn(min-content, 0.25rem 0.75rem, $primary, none, 0.25rem);
}

.btn_secondary {
  @extend .btn_reset;
  @extend .btn_animate;
  @include btn(auto, 0.4rem 0.8rem, $white, none, none);
  color: $primary50;

  &:is(:hover, :active) {
    background: $primary50;
    color: $white !important;
  }
}

.btn_outlined {
  @include btn(min-content, 0.5rem 1rem, $white, 2px solid $black, 0.5rem);
  @include font($font-secondary, 1rem, 1rem, 600, $black);
}

.btn_filled {
  @include btn(min-content, 0.5rem 1rem, $white, 2px solid $black, 0.75rem);
  @include font($font-secondary, 1rem, 1rem, 600, $white);
  background-color: $black;

  &:hover {
    background-color: $white !important;
    color: $black !important;
  }
}

.btn_pink {
  @include btn(min-content, 0.5rem 1rem, $white, 1px solid $pink, 1rem);
  @include font($font-secondary, 1rem, 1rem, 400, $pink);
}

/* CSS */
.button-1 {
  align-items: center;
  appearance: button;
  // background-color: #0276FF;
  background-image: radial-gradient(100% 100% at 100% 0, #5adaff 0, #5468ff 100%);
  // border-radius: 8px;
  height: auto;
  border-style: none;
  box-shadow: rgba(255, 255, 255, 0.26) 0 1px 2px inset;
  color: #fff;
  cursor: pointer;
  // display: flex;
  // flex-direction: row;
  flex-shrink: 0;
  font-family: $font-primary;
  font-size: 100%;
  line-height: 1.15;
  // margin: 0;
  padding: 10px 21px;
  text-align: center;
  text-transform: none;
  transition: color .13s ease-in-out, background .13s ease-in-out, opacity .13s ease-in-out, box-shadow .13s ease-in-out;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;

  &:active {
    scale: 0.95;
  }
}

.button-2 {
  @extend .button-1;
  background-image: radial-gradient(100% 100% at 100% 0, #d9f7ff 0, #ffffff 100%);
  color: $primary;

  &:hover {
    color: $primary !important;
  }
}

.button-3{
  @extend .button-1;
  background: $primary;
}

.button-4{
  @extend .button-3;
}

.button-22:active {
  background-color: #006AE8;
}

.button-22:hover {
  background-color: #1C84FF;
}