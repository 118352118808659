.m_auto {
  margin: auto;
}

.m_0 {
  margin: 0;
}

.m_1 {
  margin: 0.25rem;
}

.m_2 {
  margin: 0.5rem;
}

.m_3 {
  margin: 0.75rem;
}

.m_4 {
  margin: 1rem;
}

.m_5 {
  margin: 1.25rem;
}

.m_6 {
  margin: 1.5rem
}

.m_7 {
  margin: 1.75rem
}

.m_8 {
  margin: 2rem;
}

// MARGING X
.mx_auto {
  margin-left: auto;
  margin-right: auto;
}

.mx_8 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.mx_0 {

  margin-right: 0;
}

// MARGIN Y

.my_2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.my_4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my_8 {
  margin-block: 2rem;
}

.my_12{
  margin-block: 3rem;
}

.my_auto{
  margin-block: auto;
}

// MARGIN TOP
.mt_1 {
  margin-top: 0.25rem;
}

.mt_2 {
  margin-top: 0.5rem;
}

.mt_3 {
  margin-top: 0.75rem;
}

.mt_4 {
  margin-top: 1rem;
}

.mt_8 {
  margin-top: 2rem;
}

.mt_10 {
  margin-top: 2.5rem;
}

.mt_12 {
  margin-top: 3rem;
}

.mt_14 {
  margin-top: 4rem;
}

.mt_16 {
  margin-top: 5rem;
}

.mt_20 {
  margin-top: 6rem;
}

// MARGIN BOTTOM
.mb_0 {
  margin-bottom: 0;
}

.mb_1 {
  margin-bottom: 0.25rem;
}

.mb_2 {
  margin-bottom: 0.5rem;
}

.mb_3 {
  margin-bottom: 0.75rem;
}

.mb_4 {
  margin-bottom: 1rem;
}

.mb_8 {
  margin-bottom: 2rem;
}

// MARGIN LEFT
.ml_0 {
  margin-left: 0;
}

.ml_1 {
  margin-left: 0.25rem;
}

.ml_3{
  margin-left: 0.75rem;
}

.mx_auto {
  margin-right: auto;
  margin-left: auto;
}