.fit_cover {
  object-fit: cover;
}

.img_fluid {
  max-width: 100%;
  height: auto;
}

.ce_no_re_fi {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}