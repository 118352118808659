.h_100vh {
  height: 100vh;
}

.max_h_2 {
  max-height: 2rem;
}

.max_h_4 {
  max-height: 4rem;
}

.max_h_5 {
  max-height: 5rem;
}

.h_40 {
  height: 2.5rem;
}

.h_48{
  height: 3rem;
}

.min_h_100 {
  min-height: 100%;
}

.min_h_450 {
  min-height: 450px;
}

.h_4r{
  height: 4rem;
}

.h_6r {
  height: 6rem;
}

.h_12r {
  height: 12rem;
}