@mixin font($font-family, $font-size, $line-height, $font-weight, $color) {
  font-family: $font-family;
  font-size: $font-size;
  line-height: $line-height;
  font-weight: $font-weight;
  color: $color;
}

@mixin btn($width, $padding, $bgColor, $border, $borderRadius, $boxShadow: none, $height: max-content) {
  width: $width;
  padding: $padding;
  background-color: $bgColor;
  border: $border;
  border-radius: $borderRadius;
  box-shadow: $boxShadow;
  height: $height;
}

@mixin border($width: 1px, $style: solid, $color: black) {
  border: #{$width} #{$style} #{$color};
}

@mixin border-radius($radius: 0.25rem) {
  border-radius: $radius;
}

@mixin gradient-text($gradient) {
  background: linear-gradient($gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@mixin ellipsis-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin transition($properties, $duration, $timing-function: ease, $delay: 0s) {
  transition: $properties $duration $timing-function $delay;
}